import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { getLogin, getLoginState, loginAsync, saveAccessToken, sendEmailOtpAsync, sendMobileOtpAsync, ssoLoginAsync, verifyOtpAsync } from '../reducers/login.slice';
import { LoginRequest, OtpRequest, TicketRequest, TicketResponse, VerifyOtpRequest } from '../interfaces/login';
import {
  Login as LoginComponent
} from '@mediassistrepo/maven-old';
import { useNavigate } from 'react-router';
import { isConfigLoading } from '../reducers/config.slice';
import { validateEmail, validatePhoneNumber } from '../utils';
import { createTicketApi, multiUserLogin, multiUserLoginAccessToken, sendEmailOtp, sendMobileOtp, unlinkUser } from '../api/loginAPI';
import { useTimer } from '../utils/Timer';
import { userLoginDetails } from '../interfaces/signup.interface';
import { LoginGAEvent, getCookieValue, getPlatformFromCookie, postToNativeApp, testEmail, testPhoneNumber } from '../common/helpers';
import { LoggerConstant, LoginByContact, LoginByContact_FindMultipleUser, LoginByContact_GenericHomePage, LoginByUserName, LoginByUserName_SSORedirect, LoginContactSendEmailOtp, LoginContactSendEmailOtpFail, LoginContactSendMobileOtp, LoginContactVerifyEmailOtp, LoginContactVerifyEmailOtpFail, LoginContactVerifyMobileOtp, LoginContactVerifyMobileOtpFail, LoginUFail, LoginUSuccess, RouteConstant, emailOrMobileWrong } from '../common/common.constants';
import { ILogDto } from '../utils/log.dto';
import { log } from '../app/postRequest.service';
import { getLoggerDTO } from '../common/loggerDtoGenerator';
import { fetchMultiUserFilterDetails, forgotPasswordApi, verifyMultiUserApi } from '../api/accountAPI';
import { createTicket } from '../utils/ticket';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';
import { Helmet } from 'react-helmet-async';

const platform = getPlatformFromCookie();

export enum LOGIN_STATE {
  DEFAULT = 'DEFAULT',
  MULTIUSER = 'MULTIUSER',
  CORPORATE_VERIFICATION = 'CORPORATE_VERIFICATION',
}

export interface IMultiUser {
  username: string;
  isVerified: boolean;
}

export interface IAlert {
  type?: string;
  subject?: string;
  message: string;
  close?: Function;
}


const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginResponse = useAppSelector(getLogin);
  const configLoadingState = useAppSelector(isConfigLoading);
  const isLoginLoading = useAppSelector(getLoginState);
  const [error, setError] = React.useState<string>('');
  const [attempt, setAttempt] = React.useState<number>(3);
  const [ otpAttempt, setOtpAttempt ] = React.useState<number>(1);
  const { seconds, setSeconds } = useTimer(true);
  const [otpToken, setOtpToken] = React.useState<string>('');
  const [token, setToken] = React.useState<string>(otpToken);
  const [state, setState] = React.useState<LOGIN_STATE>(LOGIN_STATE.DEFAULT);
  const [users, setUsers] = React.useState<IMultiUser[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [otp, setOtp] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);
  const [isPasswordEnabled, setIsPasswordEnabled] = React.useState<boolean>(false);
  const [errorLoginLanding, setErrorLoginLanding] = React.useState<IAlert>();
  const [isMoreThanFive, setIsMoreThanFive] = React.useState<boolean>(false);
  const [bioMetricStatus,setBioMetricStatus]=useState(false);
  const [biometricType,setBiometricType]=useState('');

  useEffect(() => {
    postToNativeApp({"getBiometricStatus":true}) //getBiometric status from react native localstorage to find whether it is supported or not
    if (getCookieValue("platform") == "ios") {
      window.addEventListener("message", handleMessage);
      return () => {
        window.removeEventListener("message", handleMessage);
      };
    } else if (getCookieValue("platform") == "android") {
      document.addEventListener("message", handleMessage);
      return () => {
        document.removeEventListener("message", handleMessage);
      };
    }
  }, []);

  const handleMessage = (event: any) => {
    const message = JSON.parse(event.data);
    if (message.event == 'biometricStatus') {
      setBioMetricStatus(message.data.value)  //this is to show biometric tab in the login page
      setBiometricType(message.data.type)//this is to get biometric type ex - face id touch id etc
    }
  };
    const handleSubmitUsername = async (e:any, username: string, password: string) => {
      e.preventDefault();
      setLoading(true);
      const request:LoginRequest = {
        username: username,
        password: password
      }
      let res:any;
      if(username && !password){
       res = await dispatch(ssoLoginAsync(request))
       const url = RouteConstant.SSO_USER_LOGIN;
       const initialLoggerData: ILogDto = { url, requestTime: new Date().toISOString(), request: JSON.stringify(request), field5: 'Username', field8: 'Username', field9: "MAven", field10: platform } as ILogDto;
       log(getLoggerDTO(res, request, LoggerConstant.SSO_LOGIN, initialLoggerData));
      }else if(username && password){
       res = await dispatch(loginAsync(request));
       const url = RouteConstant.USER_NAME_LOGIN;
       const initialLoggerData: ILogDto = { url, requestTime: new Date().toISOString(), request: JSON.stringify(request), field5: 'Username', field8: 'Username', field9: "MAven", field10: platform } as ILogDto;
       log(getLoggerDTO(res, request, LoggerConstant.USERNAME_PASSWORD_LOGIN, initialLoggerData));
    }
    res = res?.payload;
    // const logData: ILogDto = { requestTime, responseTime: new Date().toISOString(), request: JSON.stringify(req), response: JSON.stringify(res), url, field5: username ? 'E-mail' : 'Mobile', field7: username, field8: 'Username', field9: 'MAven', field10: platform } as ILogDto;
    if (res?.redirectUrl) {
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "Continue",
        status: "Success",
        error_message:"NA"
      })
      // logData.field4 = 'Success';
      // logData.field1 = 'Redirected to SSO';
      // APIRequest.instance.log(logData);
      LoginGAEvent(LoginByUserName_SSORedirect,LoginByUserName,username,"");
      document.cookie = 'fromApp=malite;max-age=1200;domain=.mediassist.in;path=/';
        window.location.href = res?.redirectUrl;
      return;
    } else if (res?.accessToken) {
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "Continue",
        status: "Success",
        error_message:"NA"
      })
      // logData.field4 = 'Success';
      // APIRequest.instance.log(logData);
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "Continue",
        status: "Success",
        error_message:"NA"
      })
      postLogin(res)
      LoginGAEvent(LoginUSuccess,LoginByUserName,username,res);
      dataLayerPush(Constants.Instance.LOGIN_SUCCESS, { 
        login_type: "Username",
        page_type: "Main Home Page"
      });
    }else if(res?.statusCode === 205){
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "Continue",
        status: "Success",
        error_message:"NA"
      });
        window.location.href = '/signup';
      dataLayerPush(Constants.Instance.LOGIN_SUCCESS, { 
          login_type: "Username",
          page_type: "Generic Home Page Popup"
        });
    } 
    else if (res?.message && password && res?.code!= 205) {
      setErrorMessage(res?.message);
      LoginGAEvent(LoginUFail,LoginByUserName,username,res);
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "Continue",
        status: "Fail",
        error_message: res?.message[0] || "NA"
      });
      if (attempt < 1) {
        setErrorLoginLanding({ subject: 'Error', message: res?.message })
      } else {
        setErrorLoginLanding({ subject: 'Invalid Password', message: `${res?.message} <br /> ${res?.message[0].includes('locked') ? "" : ` Attempts remaining: ${attempt - 1} `} ` })
      }
      setAttempt(attempt - 1);
      // logData.field4 = 'Fail';
      // logData.field6 = ( res?.message && res?.message[0] ) || 'Network disconnected.';
      // APIRequest.instance.log(logData);
    } else {
      setIsPasswordEnabled(true);
      setAcceptTerms(false);
    }
    setLoading(false);

  }



  const handleOtpSend = async (loginValue: string): Promise<any> => {
    var request: OtpRequest = {
      email: null,
      mobile: null
    }
    setError('');
    if (validateEmail(loginValue)) {
      request.email = loginValue;
     const res = await dispatch(sendEmailOtpAsync(request));
     const url = RouteConstant.EMAIL_OTP_LOGIN;
     const initialLoggerData: ILogDto = { url, requestTime: new Date().toISOString(), request: JSON.stringify(request), field8: 'SendOTP', field9: 'MAven', field10: platform, field7: request.mobile, field5: testEmail(request.mobile) ? 'E-mail' : 'Mobile' } as ILogDto;
     log(getLoggerDTO(res, request, LoggerConstant.SEND_OTP, initialLoggerData));
     return res?.payload;
    }else{
      request.mobile = loginValue;
      const res = await dispatch(sendMobileOtpAsync(request));
      const url = RouteConstant.MOBILE_OTP_LOGIN;
      const initialLoggerData: ILogDto = { url, requestTime: new Date().toISOString(), request: JSON.stringify(request), field8: 'SendOTP', field9: 'MAven', field10: platform, field7: request.mobile, field5: testEmail(request.mobile) ? 'E-mail' : 'Mobile' } as ILogDto;
      log(getLoggerDTO(res, request, LoggerConstant.SEND_OTP, initialLoggerData));  
      return res?.payload;
    }

  }

  const handlVerifyotp = async (loginVale: any, otp: any): Promise<any> => {
    const request: VerifyOtpRequest = {
      otp: otp,
      token: loginResponse.encryptedToken,
      loginVale
    }
    const res = await dispatch(verifyOtpAsync(request));
    const url = RouteConstant.OTP_VERIFICATION;
    const initialLoggerData: ILogDto = { url, requestTime: new Date().toISOString(), request: JSON.stringify(request), field8: 'VerifyOTP', field9: 'MAven', field10: platform, field7: request.loginVale, field5: testEmail(request.loginVale) ? 'E-mail' : 'Mobile' } as ILogDto;
    log(getLoggerDTO(res, request, LoggerConstant.OTP_VERIFICATION, initialLoggerData));
    return res?.payload;
  }


  const authenticateUser = (verifiedResponse: any) => {
    if (verifiedResponse.accessToken) {
      let det: userLoginDetails = new userLoginDetails();
      det.isLoginSkipped = false;
      det.isUserLoggedIn = true;
      localStorage.setItem('accessToken', verifiedResponse.accessToken);
      localStorage.setItem('X-REF-DATA', verifiedResponse.xRefData);
      localStorage.setItem('firstTimeLogin', verifiedResponse.isFirstLogin?.toString());
      postToNativeApp({
        isLinkPolicy:localStorage.getItem('isLinkPolicy') == 'true' ? true : false,
        accessToken: verifiedResponse?.accessToken || null,
        accessTokenExpirationTime: verifiedResponse?.expiry?.toString() || null,
        userDeviceToken: verifiedResponse?.deviceId,
        xRefData: verifiedResponse.xRefData
      });
    }
  };

  const postLogin = (result: any) => {
    authenticateUser(result);
  }



  const validateOtp = async (loginValue: any, otp: any) => {
    setLoading(true);
    setOtp("");
    const requestTime = new Date().toISOString();
    const res = await handlVerifyotp(loginValue, otp);
    // const logData: ILogDto = { requestTime, responseTime: new Date().toISOString(), request: JSON.stringify(req), response: JSON.stringify(res), field8: 'VerifyOTP', url, field9: 'MAven', field10: platform, field7: loginValue, field5: testEmail(loginValue) ? 'E-mail' : 'Mobile' } as ILogDto;
    const emailValidation = validateEmail(loginValue);
    setOtpAttempt(otpAttempt + 1);
    if (res?.accessToken) {
      // logData.field1 = 'OTP Verified';
      // logData.field4 = 'Success';
      // APIRequest.instance.log(logData);
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "verify",
        status: "Success",
        error_message:"NA"
      })
      sessionStorage.setItem('flowname', emailValidation ? 'E-mail' : 'Mobile');
      emailValidation ? LoginGAEvent(LoginContactVerifyEmailOtp,LoginByContact,loginValue,"") : LoginGAEvent(LoginContactVerifyMobileOtp,LoginByContact,loginValue,"");
      postLogin(res)
      dataLayerPush(Constants.Instance.LOGIN_SUCCESS, { 
        login_type: emailValidation ? "Email" : "Mobile",
        page_type: "Main Home Page"
      });
    } if ([202, 205].includes(res?.statusCode)) {
      // logData.field4 = 'Success';
      // logData.field1 = 'OTP Verified|User not found';
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "verify",
        status: "Success",
        error_message:"NA"
      })
      localStorage.setItem(emailValidation ? 'loginEmail' : 'loginMobile', loginValue);
       LoginGAEvent(LoginByContact_GenericHomePage,LoginByContact,loginValue,"");
      // APIRequest.instance.log(logData);
      // window.location.href = '/signUp';
      navigate("/signup");
      dataLayerPush(Constants.Instance.LOGIN_SUCCESS, { 
        login_type: emailValidation? "Email":"Mobile",
        page_type: "Generic Home Page Popup"
      });
      return;
    } else if (res?.encryptedToken) {
      // logData.field1 = 'OTP Verified|MultiUser';
      // logData.field4 = 'Success';
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "verify",
        status: "Success",
        error_message:"NA"
      })
      setToken(res?.encryptedToken);
      setUsers(res.users || []);
      // APIRequest.instance.log(logData);
      setState(res.moreThanFive ? LOGIN_STATE.CORPORATE_VERIFICATION : LOGIN_STATE.MULTIUSER);
      LoginGAEvent(LoginByContact_FindMultipleUser,LoginByContact,loginValue,res);
      return;
    } else {
      // logData.field4 = 'Fail';
      // logData.field6 = ( res?.message && res?.message[0] ) || 'Network disconnected.';
      // APIRequest.instance.log(logData);
      dataLayerPush(Constants.Instance.LOGIN_FORM_CTA_INTERACTION, { 
        cta_text: "verify",
        status: "Fail",
        error_message: res?.message[0] || "NA"
      });
      setError(res?.message);
      emailValidation ? LoginGAEvent(LoginContactVerifyEmailOtpFail,LoginByContact,loginValue,res) : LoginGAEvent(LoginContactVerifyMobileOtpFail,LoginByContact,loginValue,res);
    }
    setLoading(false);
  }

  const sendOtp = async (loginValue: string) => {
    if ((loginValue && validateEmail(loginValue)) || (loginValue && validatePhoneNumber(loginValue))) {
      setError("");
      const res = await handleOtpSend(loginValue);
      const requestTime = new Date().toISOString();
      // const logData: ILogDto = { requestTime, responseTime: new Date().toISOString(), request: JSON.stringify(req), response: JSON.stringify(res), field8: 'ReSendOTP', url, field9: 'MAven', field10: platform, field7: loginValue, field5: testEmail(loginValue) ? 'E-mail' : 'Mobile' } as ILogDto;
      setOtpAttempt(otpAttempt + 1);
      if (res?.encryptedToken) {
        // logData.field4 = 'Success';
        // logData.field1 = 'OTP Sent';
        setToken(res?.encryptedToken);
        validateEmail(loginValue) ?  LoginGAEvent(LoginContactSendEmailOtp,LoginByContact,loginValue,"") : LoginGAEvent(LoginContactSendMobileOtp,LoginByContact,loginValue,"");
        setSeconds(60);
      } else {
        // logData.field4 = 'Fail';
        // logData.field6 = ( res?.message && res?.message[0] ) || 'Network disconnected.';
        setError(res?.message || 'Unable to proceed at this moment.');
        validateEmail(loginValue) ?  LoginGAEvent(LoginContactSendEmailOtpFail,LoginByContact,loginValue,res) : LoginGAEvent(LoginContactSendMobileOtp,LoginByContact,loginValue,res);
      }
      // APIRequest.instance.log(logData);
    } else {
      setError('We could not find an account matching the email/mobile number you entered. Please try again.');
      LoginGAEvent(emailOrMobileWrong,LoginByContact,loginValue,"");
    }
  }
  const handleMultiUserRequest = async(request: any) => {
    const response = await multiUserLogin(request);
    return response.data
  }

  const handleMultipleUserProceed = async(req: any) => {
    const response = await multiUserLoginAccessToken(req);
    saveAccessToken(response.data);
    return response.data
  }

  const handleUnlink = async(request:any) => {
    const response = await unlinkUser(request)
    return response.data;
  }

  const filterMultiUser = async(request:any) => {
    const response = await fetchMultiUserFilterDetails(request);
    return response.data;
  } 

  const verifyMultiUser = async(req:any) => {
    const response = await verifyMultiUserApi(req);
    return response.data;
  }

  const handleForgotPassword = async(req: any) => {
    const response = await forgotPasswordApi(req);
    return response.data;
  }

  useEffect(() => {
    if (loginResponse && loginResponse.message && loginResponse.message[0] === "No user found associated with provided credntials.") {
      navigate("/signup");
    }
  }, [loginResponse])

  useEffect(() => {
    setLoading(isLoginLoading);
  }, [isLoginLoading]);

  require("@mediassistrepo/maven-old/dist/style.css");
  return (
    <div>
      <Helmet>
        <title>MAven App Login - Manage Your Health Insurance Anytime, Anywhere</title>
        <meta name="description" content="Log in to the MAven App to track claims, access your eCard, and manage healthcare benefits seamlessly. Stay connected with real-time claim updates and personalised support." />
      </Helmet>
      <LoginComponent
        loading={loading}
        handleUsernameSubmit={handleSubmitUsername}
        res={loginResponse ? loginResponse : {}}
        handleOtpClick={handleOtpSend}
        validateOtp={validateOtp}
        handleRedirect={() => {
          // navigate(0)
        }}
        handleMultiUserRequest={handleMultiUserRequest}
        handleLinkPolicyRedirect={() => {
          dataLayerPush(Constants.Instance.VERIFY_ACCOUNT_CTA_CLICK, { 
            cta_text: "Link Policy",
            Corporate:"NA" 
          });
          navigate("/link-policy")}}
        handleGuestRedirect={() => {
          navigate("/signup")
          dataLayerPush(Constants.Instance.MULTIPLE_ACCOUNTS_CTA_INTERACTION, { 
            cta_text: "Guest",
            status: "Success"
          });}}
        handleSendOtp={sendOtp}
        error={error}
        setError={setError}
        attempt={attempt}
        setAttempt={setAttempt}
        seconds={seconds}
        setSeconds={setSeconds}
        otpToken={otpToken}
        setOtpToken={setOtpToken}
        token={token}
        setToken={setToken}
        state={state}
        setState={setState}
        users={users}
        setUsers={setUsers}
        setLoading={setLoading}
        otp={otp}
        setOtp={setOtp}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        acceptTerms={acceptTerms}
        setAcceptTerms={setAcceptTerms}
        isPasswordEnabled={isPasswordEnabled}
        setIsPasswordEnabled={setIsPasswordEnabled}
        errorLoginLanding={errorLoginLanding}
        setErrorLoginLanding={setErrorLoginLanding}
        handleLoginRedirect={() => window.location.reload()}
        handleMultipleUserProceed={handleMultipleUserProceed}
        handleUnlink={handleUnlink}
        filterMultiUser={filterMultiUser}
        verifyMultiUser={verifyMultiUser}
        isMoreThanFive={isMoreThanFive} 
        setIsMoreThanFive={setIsMoreThanFive}
        createTicket={createTicket}
        otpAttempt={otpAttempt} 
        setOtpAttempt={setOtpAttempt}
        forgotPasswordApi={handleForgotPassword}
        dataLayerPush={dataLayerPush}
        showBiometric = {bioMetricStatus}
        biometricType={biometricType}
      />
    </div>
  )
}

export default Login