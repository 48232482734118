import React from 'react'
import {PrePostHospitalization} from '@mediassistrepo/homepage-component';

const PrePostHospitalizationSection = () => {
  return (
    <>
        <PrePostHospitalization />
    </>
  )
}

export default PrePostHospitalizationSection