import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../reducers/counter.slice';
import loginReducer from '../reducers/login.slice';
import configReducer from '../reducers/config.slice';
import  activeClaimsReducer  from '../reducers/activeClaims.slice';
import beneficiariesReducer from '../reducers/beneficiaries.slice';
import healthBenefitsReducer from '../reducers/healthBenefits.slice';
import vendorsReducer from "../reducers/vendors.slice";
import vendorUrlReducer from "../reducers/vendorUrl.slice";

import policyDetailsReducer from '../reducers/policy.slice';
import siProtectDetailsReducer from '../reducers/siProtect.slice';
import  multiUserDetailsReducer  from '../reducers/multiUserDetails.slice';
import baseProfileReducer from '../reducers/baseProfile.slice';
import kycDetailsReducer from '../reducers/kycDetails.slice';
import bankAccountDetailsReducer from '../reducers/bankAccountDetails.slice';
import familyClaimsReducer from '../reducers/familyClaims.slice';
import claimListReducer from '../reducers/claimList.slice';
import enrollmentReducer from '../reducers/enrollment.slice';
import activeIntimationReducer from '../reducers/activeIntimations.slice';
import flexDetailsReducer from '../reducers/flexDetails.slice';
import sbiUrlReducer from "../reducers/sbigUrl.slice";
import vendorUrlFormReducer from '../reducers/vendorFormUrl.slice'
import intimationRequestReducer from '../reducers/intimationRequest.slice'
import policyListReducer from '../reducers/policyList.slice'
import notificationsReducer from "../reducers/notification.slice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: loginReducer,
    config: configReducer,
    activeClaims: activeClaimsReducer,
    beneficiaries: beneficiariesReducer,
    healthBenefits: healthBenefitsReducer,
    vendors:vendorsReducer,
    vendorUrl:vendorUrlReducer,
    policyDocuments:policyDetailsReducer,
    siProtectDetails: siProtectDetailsReducer,
    multiUserDetails: multiUserDetailsReducer,
    baseProfile: baseProfileReducer,
    kycDetails: kycDetailsReducer,
    bankAccountDetails: bankAccountDetailsReducer,
    familyClaims: familyClaimsReducer,
    claimList : claimListReducer,
    enrollment: enrollmentReducer,
    activeIntimations: activeIntimationReducer,
    flexDetails: flexDetailsReducer,
    sbiUrl : sbiUrlReducer,
    policyList : policyListReducer,
    vendorUrlFrom:vendorUrlFormReducer,
    intimationRequest: intimationRequestReducer,
    notifications: notificationsReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
