import React, { useEffect } from 'react'
import { FamilyMemberComponent } from '@mediassistrepo/maven-old';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { configAsync, getConfig } from '../reducers/config.slice';
import { beneficiariesAsync, getBeneficiaries, getBeneficiariesLoading } from '../reducers/beneficiaries.slice';
import { baseProfileAsync, getBaseProfile } from '../reducers/baseProfile.slice';
import { getKycDetails, getKycDetailsLoading, kycDetailsAsync } from '../reducers/kycDetails.slice';
import { kycDetailsWithMAIDsReqPayload } from '../interfaces/familyMemberDetails.interface';
import { KycDetails, basePrfile, beneficiaryDetails, corporateConfigJSon } from '../constants/familyMemberDetails';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import Loader from './shared/Loader';
import { useNavigate } from 'react-router';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';

const FamilyMemberDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const baseProfile = useAppSelector(getBaseProfile)
  const corporateConfig = useAppSelector(getConfig)
  const beneficiaries = useAppSelector(getBeneficiaries);
  const areBeneficiariesLoading = useAppSelector(getBeneficiariesLoading);
  const areKycDetailsLoading = useAppSelector(getKycDetailsLoading);
  const kycDetails = useAppSelector(getKycDetails);
  useEffect(() => {
    // dispatch(configAsync())
    // dispatch(baseProfileAsync())

    if (beneficiaries && beneficiaries.beneficiaryDetails && beneficiaries.beneficiaryDetails.length) {
      const maidsArray = beneficiaries.beneficiaryDetails.map((item) => item.mediAssistId);
      const kycDetailsPayload = new kycDetailsWithMAIDsReqPayload();
      kycDetailsPayload.maids = maidsArray;
      dispatch(kycDetailsAsync(kycDetailsPayload))
    }

    // dispatch(beneficiariesAsync());

  }, [beneficiaries]);

  const handleEditContacts = async (data:any) => {
    dataLayerPush(Constants.Instance.EDIT_CONTACT_DETAIL_CTA_CLICK, { 
      section_name:"Family member details",
      cta_text:"Edit contact details"
    });
      navigate(`/updateContact`,{state:{familyData: data}});
  };

  const handleKycRedirect = (data:any) => {
    navigate("/new-kyc",{state: data});
  }

  if(areBeneficiariesLoading || areKycDetailsLoading) return <Loader/>

  return (
    <div style={{ paddingBottom: "50px" }}>
      <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Family Members"} />
      <FamilyMemberComponent
        base_profile={baseProfile?.data}
        beneficiaryDetails={beneficiaries?.beneficiaryDetails}
        corporateConfig={corporateConfig}
        kyc_Details={kycDetails?.kycDetails}
        handleEditContacts={handleEditContacts}
        handleKycRedirect={handleKycRedirect}
      />
      {/* <FamilyMemberComponent
        base_profile={basePrfile}
        beneficiaryDetails={beneficiaryDetails}
        corporateConfig={corporateConfigJSon}
        kyc_Details={KycDetails}
      /> */}
      {/* <FooterWeb /> */}
    </div>
  )
}

export default FamilyMemberDetails
