export class LoggerConstant {
    public static readonly USERNAME_PASSWORD_LOGIN = "USERNAME_PASSWORD_LOGIN";
    public static readonly SSO_LOGIN = "SSO_LOGIN";
    public static readonly SEND_OTP = "SEND_OTP";
    public static readonly OTP_VERIFICATION = "OTP_VERIFICATION";
    public static readonly NETWORK_DISCONNECTED = "Network disconnected";
    public static readonly SUCCESS = "Success";
    public static readonly FAIL = "Fail";
    public static readonly REDIRECT_TO_SSO = "Redirected to SSO";
    public static readonly OTP_SENT = "OTP Sent";
    public static readonly OTP_VERIFIED_SUCCESS = "OTP Verified";
    public static readonly OTP_VERIFIED_OR_USER_NOT_FOUND =
      "OTP Verified|User not found";
    public static readonly OTP_VERIFIED_OR_MULTI_USER = "OTP Verified|MultiUser";
    public static readonly ECARD = "E-CARD";
    public static readonly BENEFICIARY_API= "BENEFICIARY_API";
    public static readonly CLAIM_LIST_PAGE ='ClaimList';
    public static readonly ENROL_CLICKED='Enrol-Clicked';
    public static readonly BASE_PROFILE_API="BASE_PROFILE_API";
    public static readonly HOME_PAGE="Home"
    public static readonly POLICY="policy"
    public static readonly ONCLICK_PASSWORD='OnClickPasswordChange'
  }
  export class RouteConstant {
    public static readonly SI_PROTECT = "/profile/siProtect";
    public static readonly MULTI_USER_CHECK = "/profile/multiuser-check";
    public static readonly BANK_ACCOUNT_DETAIL = "/profile/bankAccountDetails";
    public static readonly CLAIM_LIST_FAMILY = "/claimList/family";
    public static readonly MEMBER_ENROLLMENT = "/master/enrolment";
    public static readonly KYC_DETAIL_WITH_MAID = "/kyc/kycDetailsWithMAIDs";
    public static readonly PROFILE_CONFIG = "/profile/config";
    public static readonly ACTIVE_CLAIMS = "/claim/activeClaims";
    public static readonly BENEFICIARIES = "/claim/beneficiaries";
    public static readonly BENEFICIARY_HEALTHBENEFITS =
      "/beneficiary/healthBenefits";
    public static readonly BENEFICIARY_VENDORS = "/beneficiary/vendors";
    public static readonly BENEFICIARY_VENDOR_URL = "/beneficiary/vendorUrl";
    public static readonly BASE_PROFILE = "/profile/base-profile";
    public static readonly USER_NAME_LOGIN = "/auth/username-login";
    public static readonly SSO_USER_LOGIN = "/auth/check-sso-user";
    public static readonly MOBILE_OTP_LOGIN = "/auth/otp-mobile";
    public static readonly EMAIL_OTP_LOGIN = "/auth/otp-email";
    public static readonly OTP_VERIFICATION = "/auth/otp-verify";
    public static readonly MASTER_ENCRYPTED_CLAIM =
      "/master/claimId/encrypt?claimId=";
    public static readonly PROFILE_DOCUMENTS = "/profile/documents";
    public static readonly CHANGE_PASSWORD_WITH_MOBILE_OTP =
      "/auth/changePasswordWithMobileSendOtp";
    public static readonly CHANGE_PASSWORD_WITH_EMAIL_OTP =
      "/auth/changePasswordWithEmailSendOtp";
    public static readonly PROFILE_PASSWORD_CHANGE = "/profile/password-change";
    public static readonly AUTH_CHANGE_PASSWORD = "/auth/changePassword";
  }

//For Ga Event
export const LoginByUserName ="LoginU";
export const LoginByContact ="LoginC";
export const LoginUSuccess ="LoginUSuccess";
export const LoginUFail ="LoginUFail";
export const LoginByUserName_SSORedirect ="LoginU_SSORedirect";
export const LoginByUserName_SSORedirectFail ="LoginU_SSORedirectFail";
export const LoginContactSendEmailOtp ="LoginCSendEmailOtp";
export const LoginContactSendEmailOtpFail ="LoginCSendEmailOtpFail";
export const LoginContactSendMobileOtpFail ="LoginCSendMobileOtpFail";
export const LoginContactSendMobileOtp ="LoginCSendMobileOtp";
export const LoginContactVerifyEmailOtp ="LoginCVerifyEmail";
export const LoginContactVerifyEmailOtpFail ="LoginCVerifyEmailFail";
export const LoginContactVerifyMobileOtp ="LoginCVerifyMobile";
export const LoginContactVerifyMobileOtpFail ="LoginCVerifyMobileFail";
export const LoginByContact_GenericHomePage ="LoginC_GenericHP";
export const LoginByContact_FindMultipleUser ="LoginC_FindMultipleUser";
export const emailOrMobileWrong = "Wrong email/mobile number";
export const clkIntimation = "clkIntimationExitSuccess";
export const clkIntimationFail = "clkIntimationExitFail";
export const Homecategory = "HomePage";
export const clkenrollment = "Enrollment_Clk";
export const clkSubmitClaim = "SubmitClaim_clk";
export const clkActiveClaim = "clkActiveClaim_clk";
export const policy_clk = "policy_clk";
export const Home_clk = "Home_clk_F";
export const Calim_clk = "Calim_clk_F";
export const Action_clk = "Action_clk_F";
export const Policy_clk = "Policy_clk_F";
export const Account_clk_F = "Account_clk_F";
export const FooterPage = "FooterPage";
export const clkEcardDowanloaded = "clkEcardDowanloaded";