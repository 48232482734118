export interface LoginRequest {
  username: string;
  password: string;
}

export class LoginResponse {
  accessToken?: string;
  xRefData?: string;
  expiry?: number;
  deviceId?: string;
  message?: any;
  statusCode?: number;
  encryptedToken?: string;
  redirectUrl?:string;
  errorNo?: Number;
  userConsent?: string
}

export interface OtpRequest {
  email: string;
  mobile: string;
}

export interface VerifyOtpRequest {
  otp?: string;
  token?: string;
  loginVale?:string;
}

export class TicketRequest {
  loginEmail: string; 
  attemptCount: number;
  mode: string;
  loginMobileNumber: string;
  username: string; 
  reason: string;
  employeeId: string;  
  companyName: string; 
  policyNumber: string;
  beneficiaryDob: string;
  beneficiaryName: string;
  claimId: string;
  dateOfAdmission: string;
  emailForTicket: string;
  otpContact: string;

}

export class TicketResponse {
  helpDeskTicket: HelpdeskTicketResponse;
  isSuccess: boolean;
}

export class HelpdeskTicketResponse {
  created_at: Date;
  description: string;
  due_by: Date;
  fr_escalated: boolean;
  id: number;
  notes: string[];
  priority: number;
  requester_id: number;
  source: number;
  spam: boolean;
  status: number;
  subject: string;
  updated_at: Date;
  to_emails: string[];
  attachments: string[];
  tags: string[];
  conversations: string[]
}

export class ResetPasswordRequest {
  token:  string;
  key: string;
  password : string;
}

export class ResetPasswordResponse {
  message: Array<string>;
  statusCode: number;
}