import React from "react";
import {Banner,RakshaPrimeBanner} from '@mediassistrepo/homepage-component';
import { BannerDetail } from "../../interfaces/config.interface";
import { dataLayerPush } from "../../utils/gtm";
import { useAppSelector } from "../../app/hooks";
import { getBeneficiariesLoading, getIsRakshaPrime } from "../../reducers/beneficiaries.slice";  
import { useNavigate } from "react-router";
import Loader from "../shared/Loader";

interface BannerSectionProps {
    bannerDetails: Array<BannerDetail>;
}

const BannerSection = ({bannerDetails}: BannerSectionProps) => {
  const isRakshaPrime = useAppSelector(getIsRakshaPrime);
  const navigate = useNavigate();
  const isLoading = useAppSelector(getBeneficiariesLoading);
  return (
    <> 
      {isLoading ? < Loader /> : (!isRakshaPrime && bannerDetails?.length > 0 ? (
        <div style={{ paddingTop: 15 }}>
          <Banner bannerDetails={bannerDetails} dataLayerPush={dataLayerPush} />
        </div>
      ) : ( isRakshaPrime && (
        <div style={{marginBottom:10}}>
        <RakshaPrimeBanner rakshaPrimeClick={() => navigate('/raksha-prime')} />
        </div>)
      ))}
    </>
  );
};

export default BannerSection;
