import React from "react";

export const SuccessIcon = (props: any) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <rect width={24} height={24} fill="#22C55E" rx={12} />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m17.25 9-7.219 7.5-3.281-3.41"
      />
    </svg>
  );