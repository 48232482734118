import React from 'react'
import {CashlessNetwork} from '@mediassistrepo/homepage-component';
import { useNavigate } from 'react-router';
import { getCookieValue, postToNativeApp } from '../../common/helpers';
import { HealthBenefitsResponse } from '../../interfaces/cashless.interface';
import { ConfigResponse } from '../../interfaces/config.interface';
import { useAppSelector } from '../../app/hooks';
import { getConfig } from '../../reducers/config.slice';
import { dataLayerPush } from '../../utils/gtm';
import Constants from '../../constants/Constants';

interface CashlessNetworkSectionProps {
    healthbenefitsResponse: HealthBenefitsResponse;
    setLoading: Function;
}

const CashlessNetworkSection = ({healthbenefitsResponse,setLoading}: CashlessNetworkSectionProps) => {
  const corporateConfig = useAppSelector(getConfig);
  const navigate = useNavigate(); 
  return (
    <>
    <CashlessNetwork
    dataLayerPush={dataLayerPush}
      ipdBenefits={{
      "Network Hospitals": {
        imgPath:"/assets/images/ipdBenefitsImages/NetworkHospital.png",
        handleClick:() => { 
          dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
            section_name: "Cashless Services",
            cta_text: "Network Hospitals" });
            navigate('/hospitals') }
      },
      "Plan Hospitalization":{
        imgPath:"/assets/images/ipdBenefitsImages/PlanHospitalization.png",
        handleClick:() => { 
          dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
            section_name: "Cashless Services",
            cta_text: "Plan Hospitalization" });
            navigate('/intimation') }
      }
      }}
      benefits={healthbenefitsResponse?.benefits || {}}
      opdBenefitsImages={corporateConfig?.opdBenefitsImages}
      handleNetworkHospitalClick={() => { navigate('/hospitals') }}
      handlePlanHospitalizationClick={() => navigate('/intimation')}
      handleHealthBenefitClick={(benefitName: string) => {
        dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
          section_name: "Cashless OutPatient Services",
          cta_text: benefitName });
        navigate(`/health-benefits?benefitName=${benefitName}`);
      }}
      hideIntimation={corporateConfig?.hideIntimation}
      hideOpd={false}
      hideIpd={corporateConfig?.isHospClaimSubmissionDisabled}
      handleApplyNowClick={() => { 
        dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
          section_name: "Cashless Services",
          cta_text:"Apply Now"
        });
        dataLayerPush(Constants.Instance.CLAIM_INTIMATION_INITIATE, { 
          cta_text : "Apply Now", 
          section_name : "Planning to get hospitalized?", 
          header : "CASHLESS SERVICES"
        });
        navigate("/intimation") }}
      handleBenefitClick={() => { }}
      handleCheckHospitalClick={() => { 
        dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
          section_name: "Cashless Services",
          cta_text:"Check Hospitals"
        });
        navigate("hospitals") }}
      handleKnowMoreClick={() => {
        setLoading(true)
        if (getCookieValue('source') === 'native') {
          postToNativeApp({ browserUrl: "https://blog.mediassist.in/cashless-claims-guide/" })
        } else {
          window.location.href = "https://blog.mediassist.in/cashless-claims-guide/"
        }
        setLoading(false)
      }}
    />
    </>
  )
}

export default CashlessNetworkSection