import React from 'react'
import {UpcomingHospitalization} from  '@mediassistrepo/homepage-component';
import { ActiveIntimationsResponse } from '../../interfaces/intimation.interface';

interface ActiveIntimationSectionProps {
    intimationsLoading: boolean;
    activeIntimations: ActiveIntimationsResponse;
    onClickIntimation: Function;
}

const ActiveIntimationSection = ({intimationsLoading,activeIntimations,onClickIntimation}: ActiveIntimationSectionProps) => {
  return (
    <>
            {(intimationsLoading || activeIntimations?.intimations?.length > 0) &&
              <UpcomingHospitalization
                skeletonLoader={intimationsLoading}
                intimations={activeIntimations?.intimations ?? []}
                hideXpressCheckout={true}
                onClick={(intimation: any) => onClickIntimation(intimation)}
              />}
             </>
  )
}

export default ActiveIntimationSection