import React from 'react'

import {Reimbursement} from '@mediassistrepo/homepage-component'
import { ConfigResponse } from '../../interfaces/config.interface';
import { FlexDetailsResponseDTO } from '../../interfaces/flexDetails.interface';
import { useAppSelector } from '../../app/hooks';
import { getConfig } from '../../reducers/config.slice';

interface ReimbursementSectionProps{
    flexDetailsRes: FlexDetailsResponseDTO;
    handleSubmitClaim: Function;
}

const ReimbursementSection = ({flexDetailsRes, handleSubmitClaim}: ReimbursementSectionProps) => {
  const corporateConfig = useAppSelector(getConfig);
  return (
    <>
            {
              (!corporateConfig?.isHospClaimSubmissionDisabled ||
                corporateConfig?.isDomiSubmissionEnable ||
                flexDetailsRes?.flexDetails)
              &&
              <Reimbursement
                parentHeading="REIMBURSEMENT"
                heading="Want to apply for Reimbursement?"
                subtitle="Submit and monitor your reimbursement claims online, 
                reduce wait times for faster settlements."
                buttonName="Submit Claim"
                handleSubmitClaim={handleSubmitClaim}
              />
            }
    </>
  )
}

export default ReimbursementSection