import React from 'react'
import {ServicesTailored} from "@mediassistrepo/homepage-component";
import { Service } from '../../interfaces/common.interface';
import { dataLayerPush } from '../../utils/gtm';

interface ServicesTailoredSectionProps {
    services: Array<Service>;
  }

const ServicesTailoredSection = ({services}: ServicesTailoredSectionProps) => {
  return (
    <>
        <ServicesTailored services={services}  dataLayerPush={dataLayerPush}/>
    </>
  )
}

export default ServicesTailoredSection