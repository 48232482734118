import { BaseResponse } from "./claimList.interface";

export class EncryptedClaimIdResponse extends BaseResponse{
  encrytedClaimId: string;
}

export class EncryptedDataResponse extends BaseResponse{
  encryptedToken: string;
}

export class IntimationCheckRequest {
  IntimationId: number;
}
