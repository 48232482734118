import { getBlobRequest, getRequest, postBlobRequest, postRequest } from "../app/postRequest.service";
import { BaseProfileResponse } from "../interfaces/baseProfile.interface";
import { BeneficiariesResponse } from "../interfaces/beneficiaries.interface";
import { HealthBenefitsResponse, VendorRequest, VendorUrlRequest, VendorUrlResponse, VendoresResponse, EcardByMemberRequest, SurveyJSRequestModel } from "../interfaces/cashless.interface";
import { ActiveClaimsResponse } from "../interfaces/claim.interface";
import { ConfigResponse } from "../interfaces/config.interface";
import { RouteConstant  ,LoggerConstant } from "../common/common.constants";
import { NpsFeedback } from "../common/Nps/nps.models";

// A mock function to mimic making an async request for data
export function configApi() {
  return new Promise<{ data: ConfigResponse }>((resolve) =>
    resolve(getRequest('/master/config'))
  );
}

export function activeClaimsApi() {
  return new Promise<{ data: ActiveClaimsResponse }>((resolve) =>
    resolve(getRequest('/claim/activeClaims'))
  );
}


export function beneficiariesApi() {
  return new Promise<{ data: BeneficiariesResponse }>((resolve) =>
  resolve(postRequest('/claim/beneficiaries', {}))
  )
}

export function familyClaims() {
  return new Promise<{ data: BeneficiariesResponse }>((resolve) =>
    resolve(postRequest('/claimList/family',{}))
  );
}

export function healthBenefitsApi(){
  return new Promise<{data: HealthBenefitsResponse}>((resolve) => 
    resolve(postRequest('/beneficiary/healthBenefits',{}))
  )
}

export function vendorsApi(request:VendorRequest){
  return new Promise<{data:VendoresResponse }>((resolve) => 
    resolve(postRequest('/beneficiary/vendors',request))
  )
}

export function vendorUrlApi(request: VendorUrlRequest) {
  return new Promise<{ data: VendorUrlResponse }>((resolve) =>
    resolve(postRequest('/beneficiary/vendorUrl', request))
  )
}
export function baseProfileApi() {
  return new Promise<{ data: BaseProfileResponse }>((resolve) =>
  resolve(getRequest('/profile/base-profile'))
  )
}

export function activeIntimationApi(){
  return new Promise<{data:any}>((resolve) => {
    resolve(getRequest('/claim/activeIntimations'))
  })
}

export function fetchFlexPolicies(maid:string){
  return new Promise<{data:any}>((resolve) => {
    resolve(getRequest(`/claimList/flexDetails?maid=${maid}`))
  })
}

export function downloadEcardApi(url:string){
  return new Promise<{data:any}>((resolve) => {
    resolve(getBlobRequest(url));
  })
}

export function downloadEcardByMemberApi(payload: EcardByMemberRequest){
  return new Promise<{data:any}>((resolve) => {
    resolve(postBlobRequest('/claim/ecardByMember',payload));
  })
}

export const FeedBack = async (ID: string, entityType : string) => {
  const url = `/master/feedback/${entityType}/${ID}?feedback_type=RATING`;
  return new Promise<{data :any}>((resolve)=>{
    resolve(getRequest(url));
  })
};
export const SetFeedBack = async ( requestObject :SurveyJSRequestModel) => {
  return new Promise<{data:any}>((resolve) => {
    resolve(postRequest('/master/feedback/rating',requestObject));
  })
};

export const SetNpsFeedBack = async ( requestObject :NpsFeedback) => {
  return new Promise<{data:any}>((resolve) => {
    resolve(postRequest('/master/feedback/rating',requestObject));
  })
};

export const shieldMembersApi = async ( requestObject :any) => {
  return new Promise<{data:any}>((resolve) => {
    resolve(postRequest('/member/enrolment/details',requestObject));
  })
};

