import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getConfig, isConfigLoading } from '../reducers/config.slice';
import { getActiveClaims, getActiveClaimsLoading } from '../reducers/activeClaims.slice';
import { getActiveBeneficiaries, getAllBeneficiaries, getBeneficiaries, getBeneficiariesLoading, getIsRakshaPrime, setActiveBeneficiaries, setAllBenefs, setInsurerList } from '../reducers/beneficiaries.slice';
import { getHealthBenefits } from '../reducers/healthBenefits.slice';
import { useLocation, useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import { findAndReplace, formatDateToObject, getAccessToken, getMaid } from '../utils';

import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import { checkIntimationId } from '../api/masterApi';
import DecidingFunction from '../utils/servicesTailored';
import { DecidingFunctionDTO } from '../interfaces/servicesTailored.interface';
import { getEnrollment } from '../reducers/enrollment.slice';
import { feedBackCommon, GaEventDetails, getCookieValue, handleSubmitForm, logAPIStatus, postToNativeApp } from '../common/helpers';
import { getBaseProfile, isBaseProfileLoading } from '../reducers/baseProfile.slice';
import { claimListAsync, getClaimList, getFreshRequestClaimList } from '../reducers/claimList.slice';
import { ClaimListRequest } from '../interfaces/claimList.interface';
import { activeIntimationsAsync, areIntimationsLoading, getactiveIntimations } from '../reducers/activeIntimations.slice';
import Loader from './shared/Loader';
import { Homecategory, LoggerConstant, clkActiveClaim, clkEcardDowanloaded, clkIntimation, clkIntimationFail, clkSubmitClaim, clkenrollment, policy_clk } from "../common/common.constants";
import { getLogAPIStatusDTO } from '../common/loggerDtoGenerator';
import { baseProfileApi, FeedBack, shieldMembersApi } from '../api/homeAPI';
import { IntimationDto } from '../interfaces/intimation.interface';
import { IntimationCheckRequest } from '../interfaces/master.interface';
import { getflexDetails } from '../reducers/flexDetails.slice';
import { downloadEcardByMember, downloadEcardFile } from '../utils/downloadEcard';
import { getSbiUrl, getSbiUrlLoading, sbiUrlAsync } from '../reducers/sbigUrl.slice';
import { Insurer } from '../interfaces/beneficiaries.interface';
import SurveyMain from '../common/Survey/SurveyMain';
import RedirectionModal from './RedirectionModal';
import BannerSection from './sections/BannerSection';
import ActiveIntimationSection from './sections/ActiveIntimationSection';
import ActiveClaimSection from './sections/ActiveClaimSection';
import MemberSection from './sections/MemberSection';
import ReimbursementSection from './sections/ReimbursementSection';
import CashlessNetworkSection from './sections/CashlessNetworkSection';
import PolicySection from './sections/PolicySection';
import ServicesTailoredSection from './sections/ServicesTailoredSection';
import PrePostHospitalizationSection from './sections/PrePostHospitalizationSection';
import { VendorRequest, VendorUrlRequest } from '../interfaces/cashless.interface';
import { getvendors, vendorsAsync } from '../reducers/vendors.slice';
import { getvendorUrl, isVendorUrlLoading, vendorUrlAsync } from '../reducers/vendorUrl.slice';
import { VendorUrlFormRequest } from '../interfaces/VendorFormUrlForm.interface';
import { vendorsUrlFormAsync } from '../reducers/vendorFormUrl.slice';
import { GaEvent } from '../utils/GA_services';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';
import { getNotifications, notificationsAsync } from '../reducers/notification.slice';
import { NotificationsResponse } from '../interfaces/notificationsDocument.interface';
import { getMultiUserData, getMultiUserDetails, multiUserDetailsAsync } from '../reducers/multiUserDetails.slice';
import { Helmet } from 'react-helmet-async';
import ABHABanner from './sections/ABHABanner';


const Home = (props: any) => {
  const navigate = useNavigate();
  const accessToken = getAccessToken();
  const dispatch = useAppDispatch();
  const baseProfile = useAppSelector(getBaseProfile)
  const configLoading = useAppSelector(isConfigLoading);
  const baseProfileLoading = useAppSelector(isBaseProfileLoading);
  const cachedActiveBeneficiaries  = useAppSelector(getActiveBeneficiaries);
  const cachedAllBeneficiaries  = useAppSelector(getAllBeneficiaries);
  const activeClaimsResponse = useAppSelector(getActiveClaims)
  const activeClaimsLoading = useAppSelector(getActiveClaimsLoading);
  const beneficiariesResponse = useAppSelector(getBeneficiaries)
  const benefResLoading = useAppSelector(getBeneficiariesLoading);
  const healthbenefitsResponse = useAppSelector(getHealthBenefits)
  const enrollmentDetails = useAppSelector(getEnrollment);
  const corporateConfig = useAppSelector(getConfig);
  const activeIntimations = useAppSelector(getactiveIntimations);
  const intimationsLoading = useAppSelector(areIntimationsLoading);
  const sbiUrlLoading = useAppSelector(getSbiUrlLoading);
  const vendorRes = useAppSelector(getvendors);
  const vendorUrlRes = useAppSelector(getvendorUrl);
  const isVendorurlLoading = useAppSelector(isVendorUrlLoading);
  const [bannerDetails, setBannerDetails] = useState([]);
  const claimListResponse = useAppSelector(getClaimList);
  const flexDetailsRes = useAppSelector(getflexDetails);
  const sbiUrlResponse = useAppSelector(getSbiUrl);
  const [loading, setLoading] = React.useState(false)
  const nativeApp = getCookieValue('source') === 'native' ? true : false;
  const location = useLocation();
  const [activeBenefs, setActiveBenefs] = useState(beneficiariesResponse?.beneficiaryDetails);
  const [policyDuration, setPolicyDuration] = useState<string>("");
  const [policyNumber, setPolicyNumber] = useState<string>("");
  const [feedBackModalShow,setFeedBackModalShow]=useState(false);
  const [maid,setMaid]=useState<string>('')
  const [sections,setSections] = useState([1,2,3,4,5,6,7,8,9,10]);
  const [showRedirectionModal, setShowRedirectionModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [newTab, setNewTab] = useState(false);
  const [healthBenefName,setHealthBenefName]=useState<any>([]);
  const [isVendorRedirection,setIsVendorRedirection]=useState<boolean>(false);
  const [isSbiBanner, setIsSbiBanner] = useState(false);
  const [sectionName, setSectionName] = useState<string>(Constants.Instance.MAIN_HOME_PAGE);
  const notifications = useAppSelector(getNotifications) as NotificationsResponse;
  const multiUserDetails = useAppSelector(getMultiUserDetails);
  const freshClaimListCount = useAppSelector(getFreshRequestClaimList);
  const isRakshaPrime = useAppSelector(getIsRakshaPrime)

  
  // States to keep track of remind later counts
  const [homeCount, setHomeCount] = useState(0);
  const [shouldShowPopupNextLogin, setShouldShowPopupNextLogin] = useState(false);
  const [shouldCheckPopup, setShouldCheckPopup] = useState(false);
  const [shieldMemberLastDate, setShieldMemberLastDate] = useState<any>({});

  useEffect(()=>{
    const requestObject = {
      employeeCode: (baseProfile?.data?.employeeId),
      providerCorpId: String(baseProfile?.data?.entityId)
    };

    const shieldMembersCall = async() => {
      const getResponse = await shieldMembersApi(requestObject);
      setShieldMemberLastDate(getResponse?.data);
    }
    shieldMembersCall();
  },[baseProfile])

  useEffect(() => {
    if(!multiUserDetails){
      dispatch(multiUserDetailsAsync());
    }
      if(corporateConfig && corporateConfig.homePageSectionsOrder && corporateConfig.homePageSectionsOrder.length){
        setSections(corporateConfig.homePageSectionsOrder);
      }
      if(corporateConfig &&  corporateConfig?.bannerDetails?.length){
        const exists = corporateConfig?.bannerDetails?.some(element => element.CallbackRedirection);
        const healthbenefitsDetails:any = healthbenefitsResponse;
        if(exists ){
          if(healthbenefitsDetails &&  Object?.keys(healthbenefitsDetails?.benefits)?.length){
            const benefitKeys = Object?.keys(healthbenefitsDetails?.benefits);
            const benefitInfo:any = [];
            benefitKeys?.forEach((benefitKey) => {
              if (benefitKey === "Health Checkup") {
                const benefitName = healthbenefitsDetails?.benefits[benefitKey]?.benefitName;
                benefitInfo.push(benefitKey, benefitName);
              }
            });
            if(benefitInfo?.length){
              setHealthBenefName(benefitInfo);
              const payload: VendorRequest = {
                benefitName: benefitInfo[1],
              };
              dispatch(vendorsAsync(payload));
            }
          }else{
            const payload: VendorRequest = {
              benefitName: "",
            };
            dispatch(vendorsAsync(payload));
          }
        }
      }
  },[corporateConfig]);

  useEffect(() => {
    if(getCookieValue('source') == 'native'){
      postToNativeApp({
        "accessTokenForFCM": localStorage.getItem('accessToken'),
        "xRefData": localStorage.getItem('X-REF-DATA')
      })
    }
  },[]);

  const allServices = [
    {
      image: corporateConfig?.servicesTailoredImages?.ecardService,
      title: "E-Card",
      content:
        "Use Medi Assist e-card while getting admitted to hospital to avail all policy benefits",
      handleClick:  () => {
        props.setIsEcardOpen(true);
        if (getCookieValue('source') === 'native') {
          postToNativeApp({ "hideFooter": true })
        }
      },
      decidingFunction: DecidingFunction.ecard
    },
    {
      image: corporateConfig?.servicesTailoredImages?.enrolment,
      title: "Enrolment",
      handleClick: () => { navigate("/enrollment") },
      decidingFunction: DecidingFunction.enrollment
    },
    {
      image: corporateConfig?.servicesTailoredImages?.ecashlessService,
      title: "E-Cashless",
      handleClick: () => { navigate("/intimation") },
      decidingFunction: DecidingFunction.ecashless
    },
    {
      image: corporateConfig?.servicesTailoredImages?.cashlessService,
      title: "Cashless",
      handleClick: () => { navigate("/") },
      decidingFunction: DecidingFunction.cashless
    },
    {
      image: corporateConfig?.servicesTailoredImages?.reimbursementService,
      title: "Reimbursement",
      handleClick: () => {
        handleSubmitClaim();
      },
      decidingFunction: DecidingFunction.reimbursement
    },
    {
      image: corporateConfig?.servicesTailoredImages?.hospitalService,
      title: "Hospitals",
      handleClick: () => { navigate("/hospitals") },
      decidingFunction: DecidingFunction.hospitals
    },
  ];

  const [services, setServices] = useState(allServices);

  const onClaimClick = async (item: any) => {
    GaEventDetails(clkActiveClaim, Homecategory, getMaid(), item);
    dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
      section_name: "Active Claims",
      cta_text:"NA"
    });
    const claimNumber = item?.token;
    window.location.href = ("/claimDetails/" + encodeURIComponent(claimNumber))
  }
  useEffect(() => {
    if(cachedActiveBeneficiaries){
      return;
    }
    const activeBeneficiaries = beneficiariesResponse?.beneficiaryDetails
      ?.filter((benefs) => formatDateToObject(benefs?.policyEndDate) >= new Date());
    setActiveBenefs(activeBeneficiaries);
    postToNativeApp({"activeBenefCount":activeBeneficiaries?.length})
    setActiveBenefs(activeBeneficiaries);
    dispatch(setActiveBeneficiaries(activeBeneficiaries));
  }, [beneficiariesResponse]);

  useEffect(() => {
    if(cachedAllBeneficiaries){
      return;
    }
    const allBenefs = beneficiariesResponse?.beneficiaryDetails
      ?.map((benefs) =>
      ({
        ...benefs,
        isActive: benefs?.isActive && formatDateToObject(benefs?.policyEndDate) >= new Date()
      })
      ).sort((benef1, benef2) => {
        return formatDateToObject(benef2.policyEndDate).getTime() - formatDateToObject(benef1.policyEndDate).getTime()
      });
    dispatch(setAllBenefs(allBenefs));

  }, [beneficiariesResponse]);

  useEffect(() => {
    const insurerSet = new Set<number>();
    const insurerList = Array<Insurer>();
    beneficiariesResponse?.policyDetails?.forEach((policyDetail) => {
      if (!insurerSet.has(policyDetail.policyInsCompId)) {
        const insurer = new Insurer();
        insurer.name = policyDetail.insuranceCompanyName;
        insurer.shortName = policyDetail.insuranceCompanyShortName;
        insurerList.push(insurer);
        insurerSet.add(policyDetail.policyInsCompId);
      }
    });
    if(insurerList?.length){
      sessionStorage.setItem('InsurerList_NH',JSON.stringify(insurerList));
    }
  }, [beneficiariesResponse]);

  useEffect(() => {
    if (getCookieValue('platform') == 'ios') {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    else if (getCookieValue('platform') == 'android') {

      document.addEventListener('message', handleMessage);
      return () => {
        document.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  const handleMessage = (event: any) => {
    const message = JSON.parse(event.data);
    if (message.event === 'urlToWeb') {
      if (message.data.includes('claim-list')) {
        navigate("/claim-list")
      }
      else if (message.data.includes('policy-list')) {
        navigate("/policy-list")
      }
      else if (message.data.includes('account')) {
        navigate("/account")
      }
      else {
        navigate("/")
      }
      
    }
    if (message.event === 'EcardSuccess') {
      HandlefeedBack("E_CARD");
    }
  };

  const getBenefData = () => {
    let t = [];
    let q = [];
    let benefNameArray = [];
    const data = beneficiariesResponse;
    if (data?.isSuccess) {
      if (data?.beneficiaryDetails) {
        for (let i = 0; i < data?.beneficiaryDetails.length; i++) {
          benefNameArray.push({
            id: i,
            label: data?.beneficiaryDetails[i].benefName,
            value: data?.beneficiaryDetails[i].mediAssistId,
          });
        }

      }
      if (data?.policyDetails) {
        let policyList = data?.policyDetails;

        for (let i = 0; i < policyList.length; i++) {
          let names = [];
          for (let j = 0; j < policyList[i].benefDetail.length; j++) {
            names.push(policyList[i].benefDetail[j].benefName);
          }
          const startDate = new Date(policyList[i].policyStartDate);
          const endDate = new Date(policyList[i].policyEndDate);
          let durationstartDate = `${startDate.getDate()} ${startDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${startDate.getFullYear()}`;
          let durationEndDate = `${endDate.getDate()} ${endDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${endDate.getFullYear()}`;
          if (!policyList[i].isActive) {
            let a = {
              data: {
                insuranceName: policyList[i].insuranceCompanyName,
                policy_num: policyList[i].policyNumber,
                duration: durationstartDate + " - " + durationEndDate,
                area: "India",
                policyId: policyList[i].policyId,
                names: names,
              },
              currentTab: policyList[i].isActive,
            };
            t.push(a);
          } else {
            let b = {
              data: {
                insuranceName: policyList[i].insuranceCompanyName,
                policy_num: policyList[i].policyNumber,
                duration: durationstartDate + " - " + durationEndDate,
                area: "India",
                policyId: policyList[i].policyId,
                names: names,
              },
              currentTab: policyList[i].isActive,
            };
            q.push(b);
          }
        }
        return q.length
      }
    }
  };

  useEffect(() => {
    if (baseProfile?.data) {
      postToNativeApp({ "primaryBenef": baseProfile.data.firstName })
      logAPIStatus(getLogAPIStatusDTO(LoggerConstant.HOME_PAGE, 'profile/base-profile', {}, baseProfile, LoggerConstant.HOME_PAGE));
    }
  }, [baseProfile]);

  useEffect(() => {
    if(multiUserDetails?.isMultiuser !== true ){
      if( corporateConfig?.faceIdFingerprintActivated === true && (baseProfile?.data?.profileData?.isFaceIdEnabledForUser == 'false' || !baseProfile?.data?.profileData?.isFaceIdEnabledForUser) && localStorage.getItem('isPopupShown') !== 'true') {//&& 
        postToNativeApp({ "showBiometricPopup": true })
        localStorage.setItem('isPopupShown','true')
        }
        else if( corporateConfig?.faceIdFingerprintActivated === true && (baseProfile?.data?.profileData?.isFaceIdEnabledForUser == 'true' || baseProfile?.data?.profileData?.isFaceIdEnabledForUser) && localStorage.getItem('isPopupShown') !== 'true'){
          postToNativeApp({ "showBiometricPopup": true })
          localStorage.setItem('isPopupShown','true')
        }
        else if(corporateConfig?.faceIdFingerprintActivated === false  && localStorage.getItem('isPopupShown') !== 'true') {
          localStorage.setItem('isPopupShown','true')
          postToNativeApp({"disableBiometrics":false})
        }
      }
      else if(localStorage.getItem('isPopupShown') !== 'true'){
        localStorage.setItem('isPopupShown','true')
        postToNativeApp({"disableBiometrics":false})
      }
  }, [baseProfile , corporateConfig, multiUserDetails]);

  useEffect(() => {
    if (getCookieValue('source') === 'native') {
      postToNativeApp({ "claimListCount": freshClaimListCount || 0 })
    }
  }, [claimListResponse]);

  useEffect(() => {
    if (!claimListResponse) {
      const request: ClaimListRequest = {
        claimTypeIds: [],
        maids: [],
        policyIds: []

      }
      getFilteredClaimList(request)
    }
    if (location?.state?.intimations) {
      dispatch(activeIntimationsAsync());
    }

  }, []);

  useEffect(() => {
    if (beneficiariesResponse) {
      if (beneficiariesResponse?.policyDetails && beneficiariesResponse?.policyDetails.length > 0) {
        if (getCookieValue('source') === 'native') {
          postToNativeApp({ "policyData": beneficiariesResponse?.policyDetails[0].policyNumber })
          const pribenef = beneficiariesResponse?.beneficiaryDetails.filter((x:any)=>x.relationToPrimaryId === 1);
          if(pribenef && pribenef.length > 0 && pribenef[0]?.polCorporateId && pribenef[0]?.polCorporateId != 0){
           postToNativeApp({ "policyHolderName": beneficiariesResponse?.policyDetails[0]?.polHolderName })
          };
          postToNativeApp({ "benefResponse": beneficiariesResponse?.beneficiaryDetails })
        }
        setPolicyNumber(beneficiariesResponse?.policyDetails[0].policyNumber);
      const startDate = new Date(beneficiariesResponse?.policyDetails[0].policyStartDate);
        const endDate = new Date(beneficiariesResponse?.policyDetails[0].policyEndDate);
        let durationstartDate = `${startDate.getDate()} ${startDate.toLocaleString('default', { month: 'short' })} ${startDate.getFullYear()}`
        let durationEndDate = `${endDate.getDate()} ${endDate.toLocaleString('default', { month: 'short' })} ${endDate.getFullYear()}`
        const policyDuration = durationstartDate + ' - ' + durationEndDate;
        setPolicyDuration(policyDuration);
        if (getCookieValue('source') === 'native') {
          postToNativeApp({ "policyDate": policyDuration })
        }
      }
    }
  }, [beneficiariesResponse]);



  useEffect(() => {
    if (!baseProfileLoading && !configLoading && baseProfile?.data && corporateConfig) {
      postToNativeApp({ "config": corporateConfig })
  
      const { userId, emailId, mobileNo, isEmailVerified, isMobileVerified } = baseProfile?.data;
      const { isSSOUser } = corporateConfig
      if (!localStorage.getItem('notVerified')) {
        const isLinkPolicy = localStorage.getItem("isLinkPolicy");
        if (
          !emailId ||
          !mobileNo ||
          isLinkPolicy ||
          (!isSSOUser && (!isMobileVerified || !isEmailVerified))
        ) {
            navigate(`/updateContact`)
        }
      }
    }
    if (corporateConfig) {
      postToNativeApp({ config: corporateConfig })
      const decidingFunctionDTO = new DecidingFunctionDTO();
      decidingFunctionDTO.corporateConfig = corporateConfig;
      decidingFunctionDTO.enrollmentDetails = enrollmentDetails;
      decidingFunctionDTO.activeBenefs = activeBenefs;
      const servicesToShow = allServices.filter((service) => service.decidingFunction(decidingFunctionDTO));
      setServices(servicesToShow);
    }
  }, [corporateConfig, enrollmentDetails, baseProfile, activeBenefs]);

  const HandlefeedBack= async (entityType :string)=>{
    const show= await feedBackCommon(entityType)
    setFeedBackModalShow(show);
    setMaid(localStorage.getItem("maid") )
  }
 
  const handleRedirection = (url:string, newTab = false) => {
    url = findAndReplace(url);
    if (url.includes('http') || url.includes('https')) {
      setRedirectUrl(url);
      setNewTab(newTab);
      setShowRedirectionModal(true);
      setIsSbiBanner(false);
    } else {
      navigate(url);
    }
  };

  const handleConfirmRedirect = async () => {
    if(isVendorRedirection && vendorUrlRes && vendorUrlRes?.vendorAPIURL){
      if(corporateConfig?.handleFormRequestOnUi){
        handleSubmitForm(vendorUrlRes?.vendorAPIURL,vendorUrlRes?.formData);
        return;
      }
      const payload: VendorUrlFormRequest = {
        url: vendorUrlRes?.vendorAPIURL,
        formData: {
          "message": vendorUrlRes?.formData?.message,
          "auth_tag": vendorUrlRes?.formData?.auth_tag
        }
      }
      let res = await dispatch(vendorsUrlFormAsync(payload));
      if (getCookieValue('source') === 'native') {
        postToNativeApp({ networkHospitalUrl: res?.payload?.url })
      } else {
        window.open(res?.payload?.url, '_blank', 'noopener,noreferrer');
      }
    }
    else if (getCookieValue('source') === 'native') {
      postToNativeApp({ networkHospitalUrl: redirectUrl });
    } else {
      if (newTab) {
        window.open(redirectUrl, '_blank');
      } else {
        window.open(redirectUrl, '_blank');
      }
    }
    setShowRedirectionModal(false);
  };

  const vendorRedirection = (url: string, vendorId: number) => {
      const queryString = url?.split("?")[1];
      const params = new URLSearchParams(queryString);
      const benefitName = params?.get("benefitName");
      const payload: VendorUrlRequest = {
          vendorId: vendorId,
          benefitName: healthBenefName?.length ? healthBenefName[0] : "",
          benefitNameString: benefitName,
          selectedMemberId: getMaid()
      };
      dispatch(vendorUrlAsync(payload));
      setShowRedirectionModal(true);
  };

  useEffect(() => {
    if (!corporateConfig?.bannerDetails) return;
    const banners = corporateConfig?.bannerDetails.map((banner) => ({
      image: banner.mobileImageUrl,
      onClick: () => {
        dataLayerPush(Constants.Instance.BANNER_INTERACTION, { 
          banner_name:banner.mobileImageUrl.split('/').pop().split('.')[0]   
        });
        let searchText = "/canaraBank.png";
        if (
            banner.mobileImageUrl &&
            banner?.mobileImageUrl.includes(searchText)
        ) {
            GaEvent("clkOnCanaraBanner", {
                category: "homepage",
                action: " clicked on Canara Banner",
            });
        }
        const redirectionUrl = banner.redirectionUrl;
       let filteredData= vendorRes?.vendorList?.find(item=>Number(item.vendorId) === 1204529 || Number(item.vendorId) === 1188850);
        if(banner?.CallbackRedirection && filteredData?.vendorId){
          setIsVendorRedirection(true);
          vendorRedirection(redirectionUrl,Number(filteredData?.vendorId));
        }
        else if (redirectionUrl) {
          setIsVendorRedirection(false);
          handleRedirection(redirectionUrl, banner.isExternalLink);
        } else {
          setIsVendorRedirection(false);
          setIsSbiBanner(true);
          dispatch(sbiUrlAsync());
        }
      },
    }));
    setBannerDetails(banners);
  }, [corporateConfig?.bannerDetails, vendorRes?.vendorList]);

  useEffect(() => {
    if (sbiUrlResponse?.redirectionURL && isSbiBanner) {
      handleRedirection(sbiUrlResponse.redirectionURL);
    }
  }, [sbiUrlResponse]);

  const getFilteredClaimList = (request: ClaimListRequest) => {
    dispatch(claimListAsync(request));
  };

  const onClickIntimation = async (intimationObj: IntimationDto) => {
    dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
      section_name: "Upcoming Hospitalization",
      cta_text:"NA"
    });
    const payload = new IntimationCheckRequest();
    payload.IntimationId = intimationObj.intimationId;
    const res = await checkIntimationId(payload);

    if (res?.data?.isSuccess) {
      let maid = getMaid();
      GaEventDetails(clkIntimation, Homecategory, maid, "");
      navigate('/oop?intimationID=' + intimationObj.intimationId)
    }
    else if (!res?.data?.isSuccess) {
      GaEventDetails(clkIntimationFail, Homecategory, getMaid(), "");
      // GaEvent(ClickOnActiveIntimation, {
      //     category: Active_Intimation,
      //     label: `${intimationObj?.policyID}` + '_' + `${intimationObj?.beneficiaryName}` + '_' + `${intimationObj?.beneficiaryMediAssistID}`,
      //     userProperty:
      //         JSON.stringify({
      //             policyId: intimationObj?.policyID,
      //             maid: intimationObj?.beneficiaryMediAssistID,
      //             beneficiaryName:intimationObj?.beneficiaryName
      //         })
      // })
      // const encryptedTokenRes = await encrypt(intimationObj?.intimationId?.toString())
      navigate(`/intimationDetails/${encodeURIComponent(intimationObj.intimationId)}`);
    }

  }

  const handleSubmitClaim = () => {
    GaEventDetails(clkSubmitClaim, Homecategory, getMaid(), "");
    dataLayerPush(Constants.Instance.MAIN_HOME_PAGE_CTA_INTERACTION, { 
      section_name: "Reimbursement",
      cta_text: "Submit Claim" });
    const isHospClaimSubmissionEnabled = !corporateConfig?.isHospClaimSubmissionDisabled;
    const isDomiSubmissionEnabled = corporateConfig?.isDomiSubmissionEnable;
    const hasFlexDetails = Array.isArray(flexDetailsRes?.flexDetails) && flexDetailsRes.flexDetails.length > 0;

    if (isHospClaimSubmissionEnabled || isDomiSubmissionEnabled || hasFlexDetails) {
      if (getCookieValue('source') === 'native') {
        postToNativeApp({ "hideFooter": true })
      }
      props.setShowClaimSubmissionModal(true);
    }
  }

  const renderSections = () => {
    return sections.map((section) => {
      switch (section) {
        case 1:
          return <BannerSection key={section} bannerDetails={bannerDetails}/>;
        case 2:
          return <ActiveIntimationSection key={section} activeIntimations={activeIntimations} intimationsLoading={intimationsLoading} onClickIntimation={onClickIntimation} />
        case 3: 
            return <ActiveClaimSection activeClaimsLoading={activeClaimsLoading} activeClaimsResponse={activeClaimsResponse} onClaimClick={onClaimClick} key={section} />  
        case 4:
            return <MemberSection HandlefeedBack={HandlefeedBack} activeBenefs={cachedActiveBeneficiaries || []} baseProfile={baseProfile} isEcardOpen={props.isEcardOpen} setIsEcardOpen={props.setIsEcardOpen} setLoading={setLoading} key={section} setSectionName={setSectionName} sectionName={sectionName} shieldMemberLastDate={shieldMemberLastDate} enrollmentDetails={enrollmentDetails} /> 
        case 5:
          return <CashlessNetworkSection healthbenefitsResponse={healthbenefitsResponse}   setLoading={setLoading} key={section} />    
        case 6:
            return <ReimbursementSection flexDetailsRes={flexDetailsRes} handleSubmitClaim={handleSubmitClaim} key={section} /> 
        case 7: 
            return <PolicySection benefResLoading={benefResLoading} beneficiariesResponse={beneficiariesResponse} key={section} />
        case 8:
            return <ServicesTailoredSection services={services} key={section}  />
        case 9: 
            return <PrePostHospitalizationSection key={section} />    
        case 10:
          return <ABHABanner key={section} className="sm-mb-4" />           
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    dispatch(notificationsAsync());
  }, []);
  
  require('@mediassistrepo/homepage-component/dist/style.css');
  return (
    <>    
      <Helmet>
        <title>Simplifying Health Insurance and Benefits Management</title>
        <meta name="description" content="Welcome to the MAven App! Track claims, access reimbursements, use eCards, and manage healthcare benefits easily. Experience seamless support and stay in control of your health insurance." />
      </Helmet>
      {(loading || sbiUrlLoading || isVendorurlLoading) && <Loader />}
      <RedirectionModal
       isVisible={showRedirectionModal}
       toggleBottomModal={()=>{ setShowRedirectionModal(false)}}
       handleCancelClick={()=>{ setShowRedirectionModal(false)}}
       handleConfirmClick={handleConfirmRedirect}
       />
      {/* {(intimationsLoading) && <Loader/>} */}
      {feedBackModalShow && <div className="relative">
      <SurveyMain pageType="Home" pageTitle='E-card successfully downloaded.' Id={maid} source ={"ecard"} entity_Type={"E_CARD"} closeFeedbackModal={() => setFeedBackModalShow(!feedBackModalShow)}/>
      </div>}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {!nativeApp ? <HeaderWeb
          setSectionName={setSectionName}
          isNewNotification={notifications?.unreadNotifications?.length > 0 ? true : false} 
          isShowNotification={true}
          handleEcard={() => props?.setIsEcardOpen(true)}
          showSubmitClaim={props?.showClaimSubmissionModal}
          handleClickClinic={() => { navigate("/claimSubmission/OPD"); navigate(0) }}
          handleClickWellness={() => { navigate("") }}
          policyDuration={policyDuration}
          policyNumber={policyNumber}
          onClose={() => {
            props.setShowClaimSubmissionModal(false)

          }} /> : <div style={{ marginTop: isRakshaPrime? 68 :100 }}></div>}
        {/* <HeaderWeb /> */}
        <div style={{ paddingBottom: "90px",maxWidth:"1440px",margin:"0 auto",width:"100%" }}>
          <div className="px-5 mx-auto">
            {renderSections()}
            {/*<OpdWellnessOpdWellness /> */}
          </div>
        </div>
      </div>
      {(!nativeApp || props.showClaimSubmissionModal) && <FooterWeb setSectionName={setSectionName} handleEcard={() => props.setIsEcardOpen(true)} showSubmitClaim={props.showClaimSubmissionModal} onClose={() => {
        postToNativeApp({ "hideFooter": false })
        props.setShowClaimSubmissionModal(false)
      }} />}
    </>
  )
}

export default Home