import React from "react";
import {ActiveClaims} from "@mediassistrepo/homepage-component";
import { ActiveClaimsResponse } from "../../interfaces/claim.interface";

interface ActiveClaimSectionProps {
    activeClaimsLoading: boolean;
    activeClaimsResponse: ActiveClaimsResponse;
    onClaimClick:Function;
}

const ActiveClaimSection = ({activeClaimsLoading, activeClaimsResponse,onClaimClick}: ActiveClaimSectionProps) => {
  return (
    <>
      {(activeClaimsLoading || activeClaimsResponse?.claims?.length > 0) && (
        <ActiveClaims
          skeletonLoader={activeClaimsLoading}
          claims={activeClaimsResponse?.claims ?? []}
          handleClick={onClaimClick}
        />
      )}
    </>
  );
};
export default ActiveClaimSection;
