import React, { useEffect, useState } from 'react';
import {Notifications} from '@mediassistrepo/homepage-component';
import { NotificationsResponse, UpdateNotificationsDTO } from '../interfaces/notificationsDocument.interface';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { areNotificationsLoading, getNotifications, notificationsAsync } from '../reducers/notification.slice';
import HeaderWeb from './shared/Header';
import { updateNotificationsApi } from '../api/notificationsAPI';
import Loader from './shared/Loader';

export default function NotificationsDrawer() {
  const dispatch = useAppDispatch();
  
  const notifications = useAppSelector(getNotifications) as NotificationsResponse;
  const notificationsLoading = useAppSelector(areNotificationsLoading);
  const allNotifications = notifications?.allNotifications ?? [];
  const unreadNotifications = notifications?.unreadNotifications ?? [];
  const [isLoading, setIsLoading] = useState(false);
 
  const updateNotifications = async (ids:Array<string>) => {
    try {
      const req = new UpdateNotificationsDTO();
      req.ids = ids;
      setIsLoading(true);
      await updateNotificationsApi(req);
    } catch (error) {
    }finally{
      setIsLoading(false);
    }

  }
  
  useEffect(() => {
    dispatch(notificationsAsync());
  }, []);


  return (
    <>
      {(notificationsLoading || isLoading) && <Loader/>}
      <HeaderWeb oldHeader={true} name={`Notifications (${unreadNotifications?.length})`} isShowBackButton={true} />
      <Notifications allNotifications={allNotifications} unreadNotifications={unreadNotifications} updateNotifications={(ids: Array<string>) => updateNotifications(ids)}  />
    </>
  );
}
